import React from "react"
import { PricingData } from "../data"
import { Row } from "react-bootstrap"
import PriceCard from "./price-card"
import { Autoplay, Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

const PricingOne = () => {
  const { sectionContent, posts } = PricingData
  return (
    <section className="pricing-section sec-pad">
      <div className="thm-container">
        <div className="sec-title text-center">
          <span>{sectionContent.subText}</span>
          <h3>{sectionContent.title}</h3>
          <p>{sectionContent.content}</p>
        </div>
        <Row>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            slidesPerView={6}
            loop={true}
            loopFillGroupWithBlank={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            breakpoints={{
              "@0.00": {
                slidesPerView: 1,
                spaceBetween: 10,
              },
              "@0.75": {
                slidesPerView: 2,
                spaceBetween: 20,
              },
              "@1.00": {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              "@1.50": {
                slidesPerView: 3,
                spaceBetween: 50,
              },
            }}
            // pagination={{
            //   clickable: true,
            // }}
            // navigation={true}
            modules={[Autoplay]}
            className="brand-carousel"
          >
            {posts.map((post, index) => {
              return (
                <SwiperSlide key={index}>
                  <div style={{ width: "100%" }} key={index}>
                    <PriceCard data={post} />
                  </div>
                </SwiperSlide>
              )
            })}
          </Swiper>
        </Row>
      </div>
    </section>
  )
}

export default PricingOne
